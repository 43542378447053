// This file was automatically generated from concierge.manage.featuredvenues.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Concierge == 'undefined') { Nightloop.Templates.Concierge = {}; }
if (typeof Nightloop.Templates.Concierge.Manage == 'undefined') { Nightloop.Templates.Concierge.Manage = {}; }


Nightloop.Templates.Concierge.Manage.FeaturedVenues = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Concierge.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Concierge.Manage.FeaturedVenuesViewBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.Manage.EditFeaturedVenue = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="edit-featured-venue"><div class="edit-feature-title">', soy.$$escapeHtml(opt_data.venue_position), ' Featured Venue</div><div class="featured-venue-line"><span class="featured-venue-label">Type: </span><select name="', soy.$$escapeHtml(opt_data.name_prefix), '-featured-venue-type" class="featured-venue-type"><option value="default" ', (opt_data.featured_venue.type == 'default') ? 'selected' : '', '>Default</option><option value="custom" ', (opt_data.featured_venue.type == 'custom') ? 'selected' : '', '>Custom</option></select></div><div class="featured-venue-props ', (opt_data.featured_venue.type == 'default') ? 'no-display' : '', '"><div class="featured-venue-line"><span class="featured-venue-label">Venue: </span><select name="', soy.$$escapeHtml(opt_data.name_prefix), '-featured-venue-id" class="featured-venue-id"><option value="" ', (opt_data.featured_venue.id == '') ? 'selected' : '', '></option>');
  var venueList12253 = opt_data.venue_choices;
  var venueListLen12253 = venueList12253.length;
  for (var venueIndex12253 = 0; venueIndex12253 < venueListLen12253; venueIndex12253++) {
    var venueData12253 = venueList12253[venueIndex12253];
    output.append('<option value="', soy.$$escapeHtml(venueData12253.id), '" ', (opt_data.featured_venue.id == venueData12253.id) ? 'selected' : '', '>', soy.$$escapeHtml(venueData12253.name), '</option>');
  }
  output.append('</select><input type="hidden" name="', soy.$$escapeHtml(opt_data.name_prefix), '-featured-venue-name" value="', soy.$$escapeHtml(opt_data.featured_venue.name), '"></div><div class="featured-venue-line"><div class="featured-venue-label">Description: </div><textarea name="', soy.$$escapeHtml(opt_data.name_prefix), '-featured-venue-desc">', soy.$$escapeHtml(opt_data.featured_venue.description), '</textarea></div></div></div><hr class="featured-venue-hr"><script type="text/javascript">$(\'textarea\').keypress(function(event) {if (event.keyCode == 13) {event.preventDefault();}});<\/script>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Concierge.Manage.FeaturedVenuesViewBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div id="page-featuredvenues"><div><div class="content-header"><div class="header-text">Manage Featured Venues</div></div></div><form class="ajaxify" method="post" action="/concierge/', soy.$$escapeHtml(opt_data.concierge.id), '/manage/featuredvenues" enctype="multipart/form-data">');
  Nightloop.Templates.Concierge.Manage.EditFeaturedVenue({venue_position: 'Center', name_prefix: 'center', featured_venue: opt_data.content.featured_venues.center, venue_choices: opt_data.content.venue_choices}, output);
  Nightloop.Templates.Concierge.Manage.EditFeaturedVenue({venue_position: 'Left', name_prefix: 'left', featured_venue: opt_data.content.featured_venues.left, venue_choices: opt_data.content.venue_choices}, output);
  Nightloop.Templates.Concierge.Manage.EditFeaturedVenue({venue_position: 'Right', name_prefix: 'right', featured_venue: opt_data.content.featured_venues.right, venue_choices: opt_data.content.venue_choices}, output);
  output.append('<input type="submit"></input></form></div><script type="text/javascript">$(document).ready( function() {Pmp.Concierge.Manage.FeaturedVenues.initialize();});<\/script>');
  return opt_sb ? '' : output.toString();
};
